import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getTweeter } from "./TweetersActions";

import Tweeter from "./Tweeter";

class TweetersList extends Component {
  componentDidMount() {
    this.props.getTweeter();
  }

  render() {
    const { tweeters } = this.props.tweeters;

    if (tweeters.length === 0) {
      return <h2>Please add your first tweeter</h2>;
    }

    let items = tweeters.map(tweeter => {
      return <Tweeter key={tweeter.id} tweeter={tweeter} />;
    });

    return (
      <div>
        <h2>Tweeters</h2>
        {items}
        <hr /> {/* a */}
      </div>
    );
  }
}

TweetersList.propTypes = {
  getTweeter: PropTypes.func.isRequired,
  tweeters: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  tweeters: state.tweeters
});

export default connect(mapStateToProps, {
  getTweeter
})(withRouter(TweetersList));
