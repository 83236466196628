import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getVoices } from "./VoicesActions";

import Voice from "./Voice";

class VoicesList extends Component {
  componentDidMount() {
    this.props.getVoices();
  }

  render() {
    const { voices } = this.props.voices;

    if (voices.length === 0) {
      return <h2>No Voices</h2>;
    }

    let items = voices.map(voice => {
      return <Voice key={voice.id} voice={voice} />;
    });

    return (
      <div>
        <h2>Voices</h2>
        {items}
        <hr /> {/* a */}
      </div>
    );
  }
}

VoicesList.propTypes = {
  getVoices: PropTypes.func.isRequired,
  voices: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  voices: state.voices
});

export default connect(mapStateToProps, {
  getVoices
})(withRouter(VoicesList));
