import axios from "axios";
import { toastOnError } from "../../utils/Utils";
import { GET_VOICES } from "./VoicesTypes";

export const getVoices = () => dispatch => {
  axios
    .get("/api/v1/voices/")
    .then(response => {
      dispatch({
        type: GET_VOICES,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};
