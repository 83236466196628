import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Voice extends Component {
  render() {
    const { voice } = this.props;
    return (
      <div>
        <hr />
        <p>
          (id:{voice.id}) {voice.label} {voice.gender} {voice.lang} {voice.rate}
        </p>
      </div>
    );
  }
}

Voice.propTypes = {
  voice: PropTypes.object.isRequired
};
// const mapStateToProps = state => ({});

export default connect()(
  withRouter(Voice)
);
