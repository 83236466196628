import axios from "axios";
import { toastOnError } from "../../utils/Utils";
import { GET_TWEETER, ADD_TWEETER, DELETE_TWEETER, UPDATE_TWEETER } from "./TweeterTypes";

export const getTweeter = () => dispatch => {
  axios
    .get("/api/v1/tweeters/")
    .then(response => {
      dispatch({
        type: GET_TWEETER,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const addTweeter = tweeter => dispatch => {
  axios
    .post("/api/v1/tweeters/", tweeter)
    .then(response => {
      dispatch({
        type: ADD_TWEETER,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const deleteTweeter = id => dispatch => {
  axios
    .delete(`/api/v1/tweeters/${id}/`)
    .then(response => {
      dispatch({
        type: DELETE_TWEETER,
        payload: id
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const updateTweeter = (id, tweeter) => dispatch => {
  axios
    .patch(`/api/v1/tweeters/${id}/`, tweeter)
    .then(response => {
      dispatch({
        type: UPDATE_TWEETER,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};
